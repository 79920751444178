<template>
  <section class="container pb-8">
    <div class="exam">
      <div v-if="!examStart">
        <router-link
          class="btn btn-info mr-3"
          :to="`/practices/examHistory/${this.subjectId}`"
        >
          測試紀錄
        </router-link>

        <router-link
          class="btn btn-info"
          :to="`/practices/knowledge/${this.subjectId}`"
        >
          知識點分析
        </router-link>
      </div>

      <div
        v-if="!examStart"
        class="exam__setting"
      >
        <el-form
          style="width: 100%;"
          :model="examForm"
          :rules="examRules"
          ref="examForm"
        >
          <div class="exam-setting__list">
            <div class="exam-setting__row">
              <div class="exam-setting__title">
                <p>是否設定時間</p>
              </div>

              <div class="exam-setting__field">
                <el-radio-group v-model="examForm.isLimitTime">
                  <el-radio-button
                    v-for="item in examLimitTimeOption"
                    :key="item.value"
                    :label="item.value"
                    :value="item.label"
                  >
                    {{ item.label }}
                  </el-radio-button>
                </el-radio-group>

                <div
                  v-if="examForm.isLimitTime"
                  class="exam-setting__time"
                >
                  <el-form-item prop="limitTime">
                    <el-input v-model.number="examForm.limitTime" />
                  </el-form-item>

                  <span>分鐘</span>
                </div>
              </div>
            </div>

            <div class="exam-setting__row">
              <div class="exam-setting__title">
                <p>題目難易度</p>
              </div>

              <div class="exam-setting__field">
                <el-radio-group v-model="examForm.questionDifficulty">
                  <el-radio-button
                    v-for="item in examDifficultyList"
                    :key="item.id"
                    :label="item.id"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>

            <div class="exam-setting__row" >
              <div class="exam-setting__title">
                <p>題數</p>
              </div>

              <div class="exam-setting__field" v-if="examForm.questionDifficulty!==400">
                <div class="exam-setting__count">
                  <el-form-item prop="questionCount">
                    <el-input v-model.number="examForm.questionCount" />
                  </el-form-item>

                  <span>題</span>
                </div>
              </div>
              <div class="exam-setting__field" v-else>
              <div class="exam-setting__field mb-1">
                <div class="exam-setting__count">
                   <span class="mr-1">易</span>
                  <el-form-item prop="easyQuestionCount">
                    <el-input v-model.number="examForm.easyQuestionCount" />
                  </el-form-item>
                  <span>題</span>
                </div>
              </div>
                <div class="exam-setting__field mb-1">
                <div class="exam-setting__count">
                   <span class="mr-1">中</span>
                  <el-form-item prop="mediumQuestionCount">
                    <el-input v-model.number="examForm.mediumQuestionCount" />
                  </el-form-item>
                  <span>題</span>
                </div>
              </div>
                <div class="exam-setting__field mb-1">
                <div class="exam-setting__count">
                   <span class="mr-1">難</span>
                  <el-form-item prop="difficultQuestionCount">
                    <el-input v-model.number="examForm.difficultQuestionCount" />
                  </el-form-item>
                  <span>題</span>
                </div>
              </div>
              </div>
            </div>

            <div class="exam-setting__row">
              <div class="exam-setting__title">
                <p>是否選擇題庫</p>
              </div>

              <div class="exam-setting__field">
                <el-radio-group v-model="examForm.categoryType">
                  <el-radio-button
                    v-for="item in examCategoryOption"
                    :key="item.value"
                    :label="item.value"
                    :value="item.label"
                  >
                    {{ item.label }}
                  </el-radio-button>
                </el-radio-group>

                <div v-if="examForm.categoryType">
                  <el-form-item prop="categoryIds">
                    <el-select
                      v-model="examForm.categoryIds"
                      value-key="id"
                      multiple
                      filterable
                      placeholder="選擇題庫"
                    >
                      <el-option
                        v-for="item in examCategoryList"
                        :key="item.id"
                        :label="item.name"
                        :value="item"
                      />
                    </el-select>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="exam-setting__row">
              <div class="exam-setting__title">
                <p>設定條件</p>
              </div>

              <div class="exam-setting__field">
                <el-select
                  v-model="examForm.filters"
                  value-key="id"
                  multiple
                  filterable
                  placeholder="選擇條件"
                >
                  <el-option
                    v-for="item in examFilterList"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </div>
            </div>
          </div>
        </el-form>

        <div class="exam__button">
          <button
            type="button"
            class="btn btn-warning"
            @click="GetExamination()"
          >
            <span>開始測驗</span>
          </button>
        </div>
      </div>

      <div
        v-else
        class="exam__content"
      >
        <div id="examMain" class="exam-main">
          <div class="exam-main__title">第 {{ questionIndex + 1 }} 題</div>
          <div class="exam-main__content">
            <div class="exam-question">
              <div class="exam-question__tool">
                <span>{{ currentTopic.questionTypeName }}</span>
                <a @click="handleOpenFeedBack()">
                  <i class="fa fa-bug" />
                </a>
              </div>

              <div class="exam-question__title">
                <p>{{ currentTopic.questionName }}</p>
              </div>

              <div class="exam-question__list">
                <template v-for="(item, index) in currentTopic.answers">
                  <el-checkbox
                    v-if="item.answerName !== ''"
                    v-model="item.isAnswer"
                    :key="item.answerId"
                    :true-label="1"
                    :false-label="0"
                    class="exam-question__option"
                    @change="changeAnswer(item)"
                  >
                    {{ getTitle(index) }} {{ item.answerName }}
                  </el-checkbox>
                </template>
              </div>

              <div class="exam-question__buttonBox">
                <a
                  v-if="questionIndex !== 0"
                  class="exam-question__prev btn btn-text"
                  @click="handlePrevTopic"
                >
                  <span>上一題</span>
                </a>

                <a
                  v-if="questionIndex !== questionsData.length - 1"
                  class="exam-question__next btn btn-text"
                  @click="handleNextTopic()"
                >
                  <span>下一題</span>
                </a>

                <a
                  v-if="questionIndex === questionsData.length - 1"
                  class="exam-question__submit btn btn-primary"
                  @click="handleToAnswer()"
                >
                  <span>送出試卷</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="exam-list">
          <div class="exam-list__title">題目列表</div>
          <div class="exam-list__tabs">
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 1 }"
              @click="courseType = 1"
            >
              <i class="fa fa-book mr-2" aria-hidden="true" />
              <span>題目列表</span>
            </a>
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 2 }"
              @click="courseType = 2"
            >
              <i class="fa fa-times-circle mr-2" aria-hidden="true" />
              <span>未答題</span>
            </a>
            <a
              class="btn btn-sm w-100 btn-primary"
              :class="{ 'btn-light': courseType !== 3 }"
              @click="courseType = 3"
            >
              <i class="fa fa-tags mr-2" aria-hidden="true" />
              <span>已標記</span>
            </a>
          </div>

          <div
            v-if="questionsFilterData.length"
            class="exam-list__summary"
          >
            <i class="fa fa-th" aria-hidden="true" />
            <span>{{ summaryTime }}</span>
            <span>{{ summaryProcess }}</span>
          </div>

          <div class="exam-list__content">
            <template v-if="questionsFilterData.length">
              <div
                v-for="item in questionsFilterData"
                :key="`${item.seq}-${item.questionCode}`"
                class="exam-list__item"
                :class="{'exam-list__item--active': item.seq === questionIndex}"
              >
                <a
                  class="exam-list__item-text"
                  @click="handleShowTopic(item, item.seq)"
                >
                  <i
                    class="fa fa-check-circle"
                    :style="{ color: hasAnswer(item) ? '#cccf2d' : '#c0c4cc' }"
                  />

                  <span>第 {{ item.seq + 1 }} 題</span>
                </a>

                <div class="exam-list__item-button">
                  <a @click="handleMark(item)">
                    <i
                      class="fa fa-tag"
                      :style="{ color: item.isMark ? '#ff6363' : '#c0c4cc' }"
                    />
                  </a>
                </div>
              </div>
            </template>

            <p v-else>暫無資料</p>
          </div>

          <div class="exam-list__buttonBox">
            <a
              class="btn btn-hover-primary text-white border-right rounded-0 w-100 py-2"
              @click="handleToAnswer()"
            >
              送出試卷
            </a>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="'結束測驗'"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="examDialogShow"
      class="exam-dialog"
      center
    >
      <span>{{ examDialogText }}</span>
      <span slot="footer" class="dialog-footer">
        <button
          type="button"
          class="btn btn-info"
          @click="finishExam()"
        >
          <span>確定</span>
        </button>

        <button
          v-if="examSubmit"
          type="button"
          class="btn btn-light ml-3"
          @click="cancelEvent()"
        >
          <span>取消</span>
        </button>
      </span>
    </el-dialog>

    <FeedBack
      :show.sync="feedBackShow"
      :subject-name="subjectName"
      :question-code="questionCode"
    />
  </section>
</template>

<script>
import { GetPracticeSbujectBreadcrumb, GetPracticeQuestionDifficulty, GetPracticeFilter } from '@/api/BuyPracticeApi';
import { Examination, SaveExamination } from '@/api/MemberExamination';
import FeedBack from '@/components/Practice/FeedBack.vue';
import { GetPracticeCategorye } from '@/api/Base/BaseApi';

/* global $ */
export default {
  props: ['currentPage'],
  components: {
    FeedBack,
  },
  data() {
    const checkCount = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('題目數量錯誤'));

        return;
      }

      callback();
    };

    const checkTime = (rule, value, callback) => {
      if (value < 1) {
        callback(new Error('時間格式錯誤'));

        return;
      }

      callback();
    };

    return {
      courseType: 1,
      subjectId: '',
      subjectName: '',
      questionCode: '',
      feedBackShow: false,
      currentTopic: {
        isMark: false,
        isFavorite: false,
        isMarkAnswer: false,
        isTrueAnswer: false,
        questionCode: '',
        questionId: 0,
        questionName: '',
        questionType: 0,
        questionTypeName: '',
        questionsNote: '',
        recordNoteCount: 0,
        answers: [],
      },

      questionsData: [],
      questionIndex: 0,

      examLoading: true,
      examStart: false,

      examTimer: '',
      examTimeStart: '',
      examTimeEnd: '',
      examTimeDuration: 0,
      examTimeText: '--',

      examDialogShow: false,
      examDialogText: '',
      examSubmit: false,

      examLimitTimeOption: [
        {
          label: '不限時',
          value: 0,
        }, {
          label: '限時',
          value: 1,
        },
      ],
      examCategoryOption: [
        {
          label: '不分題庫',
          value: 0,
        }, {
          label: '加入題庫',
          value: 1,
        },
      ],
      examDifficultyList: [],
      examCategoryList: [],
      examFilterList: [],

      examForm: {
        // 科目
        subjectId: '',
        // 是否限時 0否 1是
        isLimitTime: 0,
        // 若限時給時間
        limitTime: '',
        // 題目數量
        questionCount: '',
        // 難易度
        questionDifficulty: 0,
        // 是否選擇題庫
        categoryType: 0,
        // 題庫分類編號，可多個，否的話給空
        categoryIds: null,
        // 塞選的條件，可多個，否的話給空
        filters: null,
        // 難題目數量
        easyQuestionCount: 0,
        // 中題目數量
        mediumQuestionCount: 0,
        // 易題目數量
        difficultQuestionCount: 0,
        // 是否選擇知識點
        knowledgeType: 0,
        // 知識點分類編號，可多個，否的話給空
        knowledgeIds: null,
      },
      examRules: {
        questionCount: [
          {
            required: true,
            message: '請輸入題目數量',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: ['blur', 'change'],
          },
          {
            validator: checkCount,
            trigger: ['blur', 'change'],
          },
        ],
        easyQuestionCount: [
          {
            required: true,
            message: '請輸入易題目數量',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: ['blur', 'change'],
          },
        ],
        mediumQuestionCount: [
          {
            required: true,
            message: '請輸入中題目數量',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: ['blur', 'change'],
          },
        ],
        difficultQuestionCount: [
          {
            required: true,
            message: '請輸入難題目數量',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: ['blur', 'change'],
          },
        ],
        limitTime: [
          {
            required: true,
            message: '請輸入限時分鐘',
            trigger: 'blur',
          },
          {
            pattern: /^\d+$/,
            message: '請輸入數字',
            trigger: ['blur', 'change'],
          },
          {
            validator: checkTime,
            trigger: ['blur', 'change'],
          },
        ],
        categoryIds: [
          {
            required: true,
            message: '請選擇題庫',
            trigger: ['blur', 'change'],
          },
        ],
      },

      isSubmitting: false,
    };
  },
  created() {
    this.GetAll();
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeWindow);
  },
  beforeRouteLeave(to, form, next) {
    window.removeEventListener('beforeunload', this.beforeWindow);

    this.clearTimer();

    next();
  },
  computed: {
    summaryTime() {
      return `計時：${this.examTimeText}`;
    },
    summaryProcess() {
      let questionTotal = 0;

      if (!this._.isEmpty(this.questionsData)) {
        const data = this._.filter(this.questionsData, (item) => item.responderAnswers.length > 0);
        questionTotal = data.length;
      }

      return `進度：${questionTotal}/${this.questionsData.length}`;
    },
    questionsFilterData() {
      const tmpQuestions = this._.filter(this.questionsData,
        (item) => this.courseType === 1
          || (this.courseType === 2 && item.responderAnswers.length === 0)
          || (this.courseType === 3 && item.isMark));

      return tmpQuestions;
    },
  },
  methods: {
    beforeWindow(e) {
      // eslint-disable-next-line no-alert
      if (!window.confirm()) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
    GetPracticeCategorye() {
      return new Promise((resolve, reject) => {
        const data = {
          status: 1,
          subjectId: this.subjectId,
          showAll: true, // 預設抓全部
          type: 99,
          categoryName: '',
        };
        GetPracticeCategorye(data)
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.examCategoryList = response.data.data;
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetPracticeSbujectBreadcrumb() {
      return new Promise((resolve, reject) => {
        const data = {
          Id: this.subjectId,
        };

        GetPracticeSbujectBreadcrumb(data)
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.subjectName = response.data.data.subJectName;
              this.$emit('update:currentPage', [
                {
                  name: '',
                  title: `${this.subjectName}`,
                  path: `/practices/${this.subjectId}`,
                },
                {
                  name: 'exam',
                  title: '考試去',
                  path: '',
                },
              ]);
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetPracticeQuestionDifficulty() {
      return new Promise((resolve, reject) => {
        GetPracticeQuestionDifficulty()
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.examDifficultyList = response.data.data;
              this.examForm.questionDifficulty = this.examDifficultyList[0].id;
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetPracticeFilter() {
      return new Promise((resolve, reject) => {
        GetPracticeFilter()
          .then((response) => {
            if (response.data.statusCode === 0) {
              this.examFilterList = response.data.data;
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetAll() {
      this.subjectId = this.$route.params?.id;

      Promise.all([
        this.GetPracticeCategorye(),
        this.GetPracticeSbujectBreadcrumb(),
        this.GetPracticeQuestionDifficulty(),
        this.GetPracticeFilter(),
      ])
        .then(() => {
          const query = this._.cloneDeep(this.$route.query);

          if (query?.knowledgeIds && query?.questionCount) {
            this.examForm.questionDifficulty = 999;
            this.examForm.knowledgeType = 1;
            this.examForm.knowledgeIds = [parseInt(query.knowledgeIds, 10)];
            this.examForm.questionCount = parseInt(query.questionCount, 10);

            this.GetExamination();
          }
        })
        .catch(() => {
          this.$router.replace('/error').catch();
        });
    },

    // 取得所有題目
    GetExamination() {
      if (this.examForm.questionDifficulty === 400) {
        this.examForm.questionCount = this.examForm.easyQuestionCount
            + this.examForm.mediumQuestionCount
            + this.examForm.difficultQuestionCount;
        if (this.examForm.questionCount < 1) {
          this.$message({
            type: 'error',
            message: '請填寫題目數',
          });
          return false;
        }
      }

      this.$refs.examForm.validate((valid) => {
        if (valid) {
          const categoryIds = this._.map(this.examForm.categoryIds, 'id');
          const filters = this._.map(this.examForm.filters, 'id');

          const postData = {
            // 科目
            subjectId: this.subjectId,
            // 是否限時 0否 1是
            isLimitTime: this.examForm.isLimitTime,
            // 若限時給時間
            limitTime: this.examForm.isLimitTime ? this.examForm.limitTime : 0,
            // 題目數量
            questionCount: this.examForm.questionCount,
            // 難易度
            questionDifficulty: this.examForm.questionDifficulty,
            // 是否選擇題庫
            categoryType: this.examForm.categoryType,
            // 題庫分類編號，可多個，否的話給空
            categoryIds: categoryIds || null,
            // 塞選的條件，可多個，否的話給空
            filters: filters || null,
            // 易題目數
            easyQuestionCount: this.examForm.easyQuestionCount,
            // 中題目數
            mediumQuestionCount: this.examForm.mediumQuestionCount,
            // 難題目數
            difficultQuestionCount: this.examForm.difficultQuestionCount,
            // 是否選擇知識點
            knowledgeType: this.examForm.knowledgeType,
            // 知識點分類編號，可多個，否的話給空
            knowledgeIds: this.examForm.knowledgeIds || null,
          };

          Examination(postData)
            .then((response) => {
              if (response.data.statusCode === 0) {
                this.data = this._.cloneDeep(response.data.data);

                this._.forEach(this.data.questions, (item) => {
                  this._.sortBy(item.answers, ['answerId']);
                });

                this._.forEach(this.data.questions, (item, index) => {
                  this.$set(item, 'seq', index);
                  this.$set(item, 'isMark', false);
                });

                this.questionsData = this._.cloneDeep(this.data.questions);

                this._.forEach(this.questionsData, (item) => {
                  this._.forEach(item.answers, (answer) => {
                    // eslint-disable-next-line no-param-reassign
                    answer.isAnswer = 0;
                  });
                });

                this.handleShowTopic(this.questionsData[0], 0);

                this.startTimer();

                this.examStart = true;
              }
            })
            .catch(() => {
              this.$router.replace('/error').catch();
            });
        } else {
          this.$message({
            type: 'error',
            message: '請填寫完整設定',
          });
        }
      });

      return true;
    },

    // 顯示選擇的題目在畫面上
    handleShowTopic(topic, index) {
      this.currentTopic = topic;
      this.questionIndex = index;

      this.$nextTick(() => {
        $('.exam-list__content').animate({
          scrollTop: 48 * this.questionIndex,
        }, 0);

        if ($(window).width() < 992) {
          $('html, body').animate({
            scrollTop: $('#examMain').offset().top - 62,
          }, 0);
        }
      });
    },
    changeAnswer(answerItem) {
      if (this.currentTopic.questionType === 1 && answerItem.isAnswer === 1) {
        this._.forEach(this.currentTopic.answers, (item) => {
          if (item.answerId !== answerItem.answerId) {
            // eslint-disable-next-line no-param-reassign
            item.isAnswer = 0;
          }
        });
      }

      this.currentTopic.responderAnswers = this._.chain(this.currentTopic.answers)
        .filter((item) => item.isAnswer === 1)
        .map('answerId')
        .value();
    },
    handlePrevTopic() {
      const index = this.questionIndex - 1;

      this.handleShowTopic(this.questionsData[index], index);
    },
    handleNextTopic() {
      const index = this.questionIndex + 1;

      this.handleShowTopic(this.questionsData[index], index);
    },
    handleOpenFeedBack() {
      this.questionCode = this.currentTopic.questionCode;
      this.feedBackShow = true;
    },
    hasAnswer(item) {
      return item.responderAnswers.length > 0;
    },

    startTimer() {
      this.examTimeStart = new Date();

      if (this.examForm.isLimitTime) {
        this.examTimeDuration = this.examForm.limitTime;
        this.examTimeEnd = new Date(this.examTimeStart.getTime() + this.examTimeDuration * 60000);
        this.setTimer();
      }
    },
    setTimer() {
      if (this.examForm.isLimitTime) {
        this.examTimeStart = new Date();
        this.examTimeDuration = this.examForm.limitTime;
        this.examTimeEnd = this.$moment(this.examTimeStart).add(this.examTimeDuration, 'minute');

        this.examTimer = setInterval(() => {
          const now = new Date();
          const timeLeft = this.$moment(this.examTimeEnd).diff(now);
          const timeLeftSeconds = this.$moment(timeLeft).format('ss');
          const timeLeftMinutes = this.$moment(this.examTimeEnd).diff(now, 'minutes');

          if (timeLeft <= 0) {
            this.clearTimer();
            this.examTimeText = '00:00';

            this.examDialogText = '考試時間終止，測驗結束。';
            this.examDialogShow = true;

            this.SaveExamination();
          } else {
            this.examTimeText = `${timeLeftMinutes}:${timeLeftSeconds}`;
          }
        }, 1000);
      }
    },
    clearTimer() {
      clearInterval(this.examTimer);
    },

    SaveExamination() {
      this.isSubmitting = true;

      const examinationEndDate = new Date();

      const postData = {
        subjectId: this.subjectId,
        questionCount: this.data.questionCount,
        questionActualCount: this.data.questionActualCount,
        isLimitTime: this.data.isLimitTime,
        limitTime: this.data.limitTime,
        questionDifficulty: this.data.questionDifficulty,
        categoryType: this.data.categoryType,
        categoryIds: this.data.categoryIds,
        filters: this.data.filters,
        questions: this.questionsData,
        examinationStartDate: this.examTimeStart,
        examinationEndDate,
        easyQuestionCount: this.data.easyQuestionCount,
        mediumQuestionCount: this.data.mediumQuestionCount,
        difficultQuestionCount: this.data.difficultQuestionCount,
        knowledgeIds: this.data.knowledgeIds,
      };

      SaveExamination(postData)
        .then((response) => {
          if (response.data.statusCode === 0) {
            if (this.examSubmit) {
              this.$router.push(`/practices/examHistory/${this.subjectId}`);
            }

            return;
          }

          this.isSubmitting = false;

          this.$router.replace('/error').catch();
        });
    },
    handleToAnswer() {
      if (this.isSubmitting) {
        return;
      }

      this.examDialogText = `${this.examForm.isLimitTime ? '考試時間尚未結束，' : ''}確定送出試卷？`;
      this.examSubmit = true;
      this.examDialogShow = true;
    },
    cancelEvent() {
      this.examDialogShow = false;
      this.examDialogText = '';
      this.examSubmit = false;
    },
    finishExam() {
      this.examDialogShow = false;

      if (this.isSubmitting) {
        this.$router.push(`/practices/examHistory/${this.subjectId}`);

        return;
      }

      if (this.examSubmit) {
        this.SaveExamination();
      }
    },
    handleMark(item) {
      // eslint-disable-next-line no-param-reassign
      item.isMark = !item.isMark;
    },
    getTitle(index) {
      const title = ['(A)', '(B)', '(C)', '(D)'];

      return title[index];
    },
  },
};
</script>

<style lang="scss" scoped>
// none
</style>
